import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Flex,
  Box,
  Heading
} from '@chakra-ui/react'
import { RichText } from 'prismic-reactjs'
import { CustomLink } from '../utils/customLink'
import PageHero from '../components/PageHero/PageHero'
import MobilePageHero from '../components/PageHero/MobilePageHero'
import SliceZone from '../components/SliceZone/SliceZone'
import { getImage } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Sidebar from '../components/Sidebar/Sidebar'
import MooseLottie from '../components/Footer/Moose/MooseLottie'

const DepartmentPageTemplate = (props) => {
      // Explicitly delcare props and destructure the other properties:
      const { location, data, ...rest } = props;
      const pageLocation = location.pathname

  if (!data) return null; 

  const document = data.prismicBoardPage.data
  const backgroundImage = getImage(document.hero_image.localFile)
  const image = getImage(backgroundImage);

  const menuItems = null || document.sidebar_menu.document?.data.body

  return (
    <Layout>
      <Seo title={document.page_title.text}  />

      <PageHero 
        heroImage={image}
        photoCredit={document.hero_image.copyright}
        pageTitle={document.page_headline?.text}
      />


      <Box position="relative" zIndex="1">
      <Flex w="100%" h="100%" flexWrap="wrap" flexDirection={{base: 'column-reverse', lg: 'row'}}>
        <Box w={{base: '100%', lg: '25%'}}>
              <Sidebar
                menuHeading={menuItems && document.sidebar_menu.document.data.menu_title.text}  
                menuItems={menuItems}
                pageLocation={pageLocation}
              />
           </Box>
           <Box w={{base: '100%', lg: '75%'}} bg="white" p="8" mb={{base: '4rem', md: '36rem'}}>
            <Heading as="h2" variant="page-title">
              {document.page_title.text}
            </Heading>
            <Heading as="h3" fontSize="2rem" variant="page-subtitle">
              {document.subtitle.text}
            </Heading>

            <RichText render={document.page_content.richText} serializeHyperlink={CustomLink}/>
            <SliceZone sliceZone={document.body}/>

      </Box>
      <MooseLottie />
        </Flex>
      </Box>
      
    </Layout>
  )
}



    export const query = graphql`
    query BoardPageQuery($uid: String!) {
      prismicBoardPage(uid: {eq: $uid}) {
        _previewable
        uid
        type
        data {
          page_headline {
            text
          }
          page_title {
            text
          }
          hero_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          page_content {
            richText
          }
          subtitle {
            text
          }
          sidebar_menu {
            document {
              ... on PrismicSidebarNavigation {
                id
                data {
                  menu_title {
                    text
                  }
                  body {
                    ... on PrismicSidebarNavigationDataBodySidebarNavAccordionItem {
                      id
                      slice_type
                      primary {
                        title_link {
                          url
                        }
                        navigation_group_title {
                          text
                        }
                      }
                      items {
                        link {
                          url
                        }
                        item_title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          body {
            ... on PrismicBoardPageDataBodyContactCard {
              id
              slice_type
              primary {
                department_contact_information {
                  text
                }
              }
              items {
                contact_title {
                  text
                }
                contact_phone {
                  text
                }
                contact_name {
                  text
                }
                contact_extension
                contact_email {
                  text
                }
              }
            }
            ... on PrismicBoardPageDataBodyBoardPersonCard {
              id
              slice_type
              primary {
                cards_title {
                  text
                }
              }
              items {
                title {
                  text
                }
                since {
                  text
                }
                name {
                  text
                }
                email {
                  text
                }
                content {
                  richText
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        placeholder: BLURRED
                        transformOptions: {cropFocus: CENTER}
                        layout: FULL_WIDTH
                      )
                    }
                  }
                  alt
                }
              }
            }
          }
        }
    }
  }
`

export default withPrismicPreview(DepartmentPageTemplate)
